/* eslint-disable-next-line */
window.addEventListener('load', function () {
	// pchppProVars を正常に取得できるか
	if (window.pchppProVars === undefined) return;

	// 非ログイン時のみ計測する
	if (!window.pchppProVars.isLoggedIn) ctPochippCV();
});

/**
 * クリックイベント登録用
 */
function clickedEvent(e, cvKey, target) {
	e.preventDefault();
	const currentTarget = e.currentTarget;
	ctPochippData(cvKey, target);

	// 計測後にリンク遷移
	currentTarget.onclick = () => true;
	currentTarget.click();
}

const cvKeys = [];

/**
 * クリック計測
 */
function ctPochippCV() {
	// IntersectionObserverをブラウザがサポートしているかどうか
	const isObserveSupported = window.IntersectionObserver && 'isIntersecting' in IntersectionObserverEntry.prototype;
	if (!isObserveSupported) return;

	// fetch使えるか
	if (!window.fetch) return;

	window.pochippBtnCtDelay = 10;

	// 計測
	setCountEvent('.pochipp-box[data-cvkey]', '.pochipp-box__btnwrap');
	setCountEvent('.pochipp-inline__btnwrap[data-cvkey]', ':scope');
}

const setCountEvent = (targetSelector, btnWrapperSelector) => {
	const reportTargets = document.querySelectorAll(targetSelector);
	if (0 === reportTargets.length) return;

	// 表示回数の計測用 IntersectionObserver
	const pochippObserver = new IntersectionObserver((entries) => {
		entries.forEach((entry) => {
			if (entry.isIntersecting) {
				const pBox = entry.target;
				const cvkey = pBox.getAttribute('data-cvkey');

				// 100ms後まで遅延時間を100ms増やして計測間隔を空ける
				const delayTime = window.pochippBtnCtDelay || 10;
				window.pochippBtnCtDelay += 100;
				setTimeout(() => {
					window.pochippBtnCtDelay -= 100;
				}, 100);

				// 少しだけ遅らせて計測のバッティングを回避
				setTimeout(() => {
					ctPochippData(cvkey, 'imp');
				}, delayTime);

				//表示計測は一度しか行わないように
				pochippObserver.unobserve(pBox);
			}
		});
	});

	// ボタン計測
	reportTargets.forEach((element) => {
		const cvKey = element.getAttribute('data-cvkey');
		cvKeys.push(cvKey);

		// ボタンが表示された回数の計測
		pochippObserver.observe(element);

		// ポチップブロックのクリック数を計測
		const imgLink = element.querySelector('.pochipp-box__image a');
		const titleLink = element.querySelector('.pochipp-box__title a');
		const reviewLink = element.querySelector('.pochipp-box__review a');
		const amazonLink = element.querySelector(`${btnWrapperSelector}.-amazon a`);
		const rakutenLink = element.querySelector(`${btnWrapperSelector}.-rakuten a`);
		const yahooLink = element.querySelector(`${btnWrapperSelector}.-yahoo a`);
		const mercariLink = element.querySelector(`${btnWrapperSelector}.-mercari a`);
		const customLink = element.querySelector(`${btnWrapperSelector}.-custom a`);
		const custom2Link = element.querySelector(`${btnWrapperSelector}.-custom_2 a`);

		if (imgLink) {
			imgLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedOther');
			};
		}
		if (titleLink) {
			titleLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedOther');
			};
		}
		if (reviewLink) {
			reviewLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedOther');
			};
		}
		if (amazonLink) {
			amazonLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedAmazon');
			};
		}
		if (rakutenLink) {
			rakutenLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedRakuten');
			};
		}
		if (yahooLink) {
			yahooLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedYahoo');
			};
		}
		if (mercariLink) {
			mercariLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedMercari');
			};
		}
		if (customLink) {
			customLink.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedCustom');
			};
		}
		if (custom2Link) {
			custom2Link.onclick = function (e) {
				clickedEvent(e, cvKey, 'clickedCustom2');
			};
		}
	});

	// そのページ自体の表示回数の計測
	if (cvKeys.length > 0) {
		window.pochippBtnCtDelay += 500;
		setTimeout(() => {
			window.pochippBtnCtDelay -= 500;
		}, 500);
		ctPochippData(cvKeys.join(','), 'pv');
	}
};

const ctPochippData = (cvkey, target) => {
	const postid = window.pchppProVars.postID;
	if (!postid) return;

	// 受け渡すデータ
	const params = new URLSearchParams(); // WPのajax通す時は URLSearchParams 使う
	params.append('action', 'pochipp_pro_ct_cv');
	params.append('postid', postid);
	params.append('cvkey', cvkey);
	params.append('target', target);

	// ajax実行
	sendCountFetch(params);
};

/**
 * ajax処理の実行部分
 */
const sendCountFetch = async (params) => {
	// ajaxUrl を正常に取得できるか
	const ajaxUrl = window.pchppProVars.ajaxUrl;
	if (ajaxUrl === undefined) return;

	// nonce を正常に取得できるか
	const ajaxNonce = window.pchppProVars.ajaxNonce;
	if (ajaxNonce === undefined) return;
	params.append('nonce', ajaxNonce);

	await fetch(ajaxUrl, {
		method: 'POST',
		cache: 'no-cache',
		body: params,
	}).then((response) => {
		if (response.ok) {
			// console.log などで一回 response.json() 確認で使うと、responseJSONでbodyがlockされるので注意
			return response.json();
		}
		throw new TypeError('Failed ajax!');
	});
};
